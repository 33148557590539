import { createValidator, composeValidators } from 'revalidate';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { isRequiredWithMessage } from "Utils/validators";
import { BTC_OPTION } from 'Enums/WalletType';

export const isValidEthAddress = createValidator(
  (message) => (walletAddress) => {
    if (walletAddress && !/^(0x)?[0-9a-fA-F]{40}$/i.test(walletAddress)) {
      return message;
    }
  },
  (message) => message
);

export const isValidBtcAddress = createValidator(
  (message) => (walletAddress) => {
    if (
      walletAddress &&
      !/^([13][a-km-zA-HJ-NP-Z1-9]{25,34}|bc1[ac-hj-np-zAC-HJ-NP-Z02-9]{11,71})$/i.test(
        walletAddress
      )
    ) {
      return message;
    }
  },
  (message) => message
);

export const walletAddressValidator = (cryptoType) => {
  return composeValidators(
    isRequiredWithMessage(
      i18nextTranslate(i18nextKeys.validatorWalletAddressRequired)
    ),
    cryptoType === BTC_OPTION
      ? isValidBtcAddress(i18nextTranslate(i18nextKeys.validatorWalletAddressInvalid))
      : isValidEthAddress(i18nextTranslate(i18nextKeys.validatorWalletAddressInvalid))
  )();
};

export const checkboxValidator = createValidator(
  (message) => (value, required = true) => {
    if (required && !value) {
      return message;
    }
  },
  (message) => message
);
