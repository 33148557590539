import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { Button } from 'Components/shared/buttons';
import api from 'Api';
import { CustomTerms, Terms } from 'Components/shared/Terms';
import { PAYMENT_METHOD } from 'Enums/PaymentMethod';
import { ORDER_STATUS } from 'Enums/OrderStatus';
import useHandleError from 'Utils/handleError';
import BankDetailsInfo from './BankDetailsInfo';
import AmountAndReference from 'Features/Checkout/AmountAndReference';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import CheckoutTimer from 'Features/Checkout/CheckoutTimer';
import { ROUTES } from 'Router/Routes';
import { ConfigContext } from 'States/config/configState';

const BankTransfer = ({
  order,
  paymentMethod,
  defaultLanguage,
  goBack = () => {}
}) => {
  const handleError = useHandleError();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    config: {
      checkoutSettings: {
        paymentMethods
      }
    }
  } = useContext(ConfigContext);

  const submitBankTransfer = async () => {
    try {
      setLoading(true);
      await api.PurchaseOrder.updateDelta(order.Id, {
        Status: ORDER_STATUS.WaitingForPayment,
        PaymentMethod: PAYMENT_METHOD.BankTransfer,
      });
      history.push({
        pathname: ROUTES.checkoutPendingBankTransfer,
        state: {
          order,
          paymentMethod,
          defaultLanguage
        }
      });
    } catch (error) {
      setLoading(false);
      const message = i18nextTranslate(
        i18nextKeys.errorCartCheckoutPaymentUpdateStatusError
      );
      handleError({ error, message });
    }
  };

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '156px'
  };

  return loading ? (
    <div className="flex justify-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div
      data-qa="checkout-bankTransfer"
      className="flex flex-col color-8 select-none text-left"
    >
      <div className="color-8 p-16 bg-4_10 rounded w-full mb-32">
        <CheckoutTimer
          startDateISO={order.ReceivedOn}
          durationISO={order.timeouts.AbortAfter}
          showTimer
        />
      </div>
      <h1 className="text-xsm xxl:text-smb font-extrabold uppercase">
        {i18nextTranslate(
          i18nextKeys.checkoutPaymentMethodsBankTransferTransferToBeneficiary
        )}
      </h1>
      <p className="text-sm xxl:text-base font-light xxl:font-normal mb-24">
        ({i18nextTranslate(i18nextKeys.checkoutPaymentMethodsBankTransferClickToCopy)})
      </p>

      <BankDetailsInfo
        paymentMethod={paymentMethod}
        defaultLanguage={defaultLanguage}
      />
      <p className="font-bold text-xs xxl:text-sm mb-4">
        {i18nextTranslate(
          i18nextKeys.checkoutPaymentMethodsBankDetailsAmountAndReference
        )}
      </p>
      <AmountAndReference
        amount={order.AmountDue}
        currency={order.AmountDueCurrency}
        reference={order.ReferenceCode}
      />

      <FinalForm
        onSubmit={submitBankTransfer}
        subscription={{ invalid: true }}
        render={({ handleSubmit, invalid }) => (
          <form className="mt-32 flex flex-col gap-32">
            <div className="flex flex-col gap-16">
              {paymentMethods[0].customTerms.map((translations, index) => (
                <CustomTerms
                  key={index}
                  index={index}
                  defaultLanguageCode={defaultLanguage.Code}
                  translations={translations}
                  required
                />
              ))}
              <Terms />
            </div>
            <div className="w-full flex justify-between">
              <Button
                text={i18nextTranslate(i18nextKeys.buttonBack)}
                onClick={goBack}
                width={buttonWidth}
                dataQa="checkout-bankTransfer-backButton"
                tertiary
              />
              <Button
                type="submit"
                text={i18nextTranslate(i18nextKeys.buttonSubmit)}
                onClick={handleSubmit}
                width={buttonWidth}
                disabled={invalid}
                dataQa="checkout-bankTransfer-submitButton"
              />
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default BankTransfer;
