import React, { useState, useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import Modal from 'Components/shared/Modal';
import Toggle from 'Components/shared/Toggle';
import { DIRECTION } from 'Helpers/icons';
import { SingleChevron } from 'Components/shared/symbols';
import AnimateHeight from 'react-animate-height';
import { Button } from 'Components/shared/buttons';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const CookieModal = ({ show, close, onAccept }) => {
  const [
    necessaryCookieSectionExpanded,
    setNecessaryCookieSectionExpanded,
  ] = useState(false);
  const [
    nonNecessaryCookieSectionExpanded,
    setNonNecessaryCookieSectionExpanded,
  ] = useState(false);
  const [nonNecessaryCookiesEnabled, setNonNecessaryCookiesEnabled] = useState(true);

  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const infoBoxClassNames =
    'flex pl-12 pr-16 md:pr-20 xxl:pr-24 py-8 items-center rounded cursor-pointer text-sm color-8 border border-color-5';

  const onToggleNonNecessaryCookies = (value) => {
    setNonNecessaryCookiesEnabled(value);
  };

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '140px'
  };

  return (
    <Modal show={show} close={close} dataQa={'cookie-modal'}>
      <div className="px-16 py-24 md:px-24 md:pb-32 flex flex-col">
        <h1 className="uppercase text-xsm xxl:text-smb font-extrabold text-left">
          {i18nextTranslate(i18nextKeys.cookieNoticeSettings)}
        </h1>

        <p className="font-light text-sm color-8 mt-16 mb-24">
          {i18nextTranslate(i18nextKeys.cookieModalDescription)}
        </p>

        <div
          className={`${infoBoxClassNames} ${necessaryCookieSectionExpanded ? ' bg-3--2' : 'bg-3--5'}`}
          onClick={() => setNecessaryCookieSectionExpanded((curVal) => !curVal)}
        >
          <SingleChevron
            size={xxl ? "16" : "14"}
            direction={
              necessaryCookieSectionExpanded
                ? DIRECTION.up
                : DIRECTION.down
            }
          />
          <p className="ml-64">
            {i18nextTranslate(i18nextKeys.cookieModalNecessaryCookieTitle)}
          </p>
          <p className="ml-auto">
            {i18nextTranslate(i18nextKeys.cookieModalAlwaysEnabled)}
          </p>
        </div>

        <AnimateHeight
          duration={750}
          height={necessaryCookieSectionExpanded ? 'auto' : 0}
        >
          <p className="ml-32 mt-16 text-sm color-8 mb-16">
            {i18nextTranslate(
              i18nextKeys.cookieModalNecessaryCookieDescription
            )}
          </p>
        </AnimateHeight>

        <div
          className={`${infoBoxClassNames} ${nonNecessaryCookieSectionExpanded ? ' bg-3--2' : 'bg-3--5'} mt-8`}
          onClick={() =>
            setNonNecessaryCookieSectionExpanded((curVal) => !curVal)
          }
        >
          <SingleChevron
            size={xxl ? "16" : "14"}
            direction={
              necessaryCookieSectionExpanded
                ? DIRECTION.up
                : DIRECTION.down
            }
          />
          <p className="ml-64">
            {i18nextTranslate(i18nextKeys.cookieModalNonNecessaryCookieTitle)}
          </p>
          <p className="ml-auto mr-8">
            {nonNecessaryCookiesEnabled
              ? i18nextTranslate(i18nextKeys.commonEnabled)
              : i18nextTranslate(i18nextKeys.commonDisabled)}
          </p>
          <Toggle
            toggled={nonNecessaryCookiesEnabled}
            onToggle={onToggleNonNecessaryCookies}
          />
        </div>

        <AnimateHeight
          duration={750}
          height={nonNecessaryCookieSectionExpanded ? 'auto' : 0}
        >
          <p className="ml-32 mt-24 text-sm color-8">
            {i18nextTranslate(
              i18nextKeys.cookieModalNonNecessaryCookieDescription
            )}
          </p>
        </AnimateHeight>

        <hr className="border-color-5 my-24" />

        <p className="text-sm color-8 font-light">
          {i18nextTranslate(i18nextKeys.cookieModalFooterDescription)}
        </p>

        <div className="flex justify-between mt-32 gap-16">
          <Button
            onClick={close}
            text={i18nextTranslate(i18nextKeys.buttonClose)}
            width={buttonWidth}
            secondary
          />
          <Button
            onClick={() => onAccept(nonNecessaryCookiesEnabled)}
            text={i18nextTranslate(i18nextKeys.buttonAccept)}
            width={buttonWidth}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CookieModal;
