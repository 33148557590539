import React, { useEffect, useState } from "react";
import { Field as FinalField } from "react-final-form";
import api from 'Api';
import TENANT_FEATURE from 'Enums/TenantFeature';
import useHandleError from 'Utils/handleError';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { DropdownField } from "Components/shared/formElements";
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import SubsectionHeading from "../../shared/SubsectionHeading";

const KYCTier = ({
  currentTier = 0,
  dataQa = "kycTier",
  disabled = false,
  contentWidth = "600px",
  fieldWidth = "404px"
}) => {
  const DEFAULT_OPTION = {
    value: 0,
    label: `${i18nextTranslate(i18nextKeys.commonKycTier)} ${0}`
  };
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([DEFAULT_OPTION]);
  const [selectedTier, setSelectedTier] = useState(DEFAULT_OPTION)
  const { data: features } = useFeatureAvailability.query({
    refetchOnMount: false
  });
  const handleError = useHandleError();

  useEffect(() => {
    const prepareOptions = async () => {
      try {
        const { value: tiers } = await api.Kyc.getTierList();
        if (tiers && tiers.length) {
          const options = tiers.map(({ Number }) => {
            const option = {
              value: Number,
              label: `${i18nextTranslate(i18nextKeys.commonKycTier)} ${Number}`
            };
            if (Number === currentTier) {
              setSelectedTier(option);
            }
            return option;
          });
          setOptions(options);
        }
      } catch (error) {
        handleError({ error });
      } finally {
        setLoading(false);
      }
    };
    if (isTenantFeatureAvailable(features, TENANT_FEATURE.kyc)) {
      prepareOptions();
    } else if (currentTier) {
      setOptions([{
        value: currentTier,
        label: `${i18nextTranslate(i18nextKeys.commonKycTier)} ${currentTier}`
      }]);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <div style={{ width: contentWidth }}>
      <SubsectionHeading
        text={i18nextTranslate(i18nextKeys.configPaymentKycTitle)}
        tooltipText={i18nextTranslate(i18nextKeys.configPaymentKycTooltip)}
        dataQa={dataQa}
      />
      <div className="flex items-center gap-16">
        <FinalField
          name="KYCTier"
          initialValue={currentTier}
          subscription={{
            error: true,
            invalid: true,
            touched: true,
            value: true
          }}
          validateFields={[]}
        >
          {({ input, meta }) => (
            <DropdownField
              label={i18nextTranslate(i18nextKeys.configPaymentKycLabel)}
              options={options}
              disabled={disabled || options.length < 2}
              dataQa={dataQa}
              style={{ width: fieldWidth }}
              input={{
                value: {
                  value: input.value,
                  label: options.length < 2
                    ? options[0].label
                    : selectedTier.label
                },
                onChange: (value, option) => {
                  setSelectedTier(option);
                  input.onChange(value);
                }
              }}
              meta={meta}
            />
          )}
        </FinalField>
        {loading && (
          <LoadingSpinner
            size="h-24 w-24"
            dataQa={`${dataQa}-loading`}
          />
        )}
      </div>
    </div>
  );
};

export default KYCTier;
