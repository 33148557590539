import React, { useContext, useEffect, useState } from 'react';
import { UiContext } from 'States/ui/uiState';
import { DIRECTION } from 'Helpers/icons';
import ConfigDropdown from 'Components/Dropdown/ConfigDropdown';
import { SingleChevron } from 'Components/shared/symbols';
import i18nextTranslate from 'Lang/i18nextTranslate';
import Text from '../Text';

const TabBarItem = ({
  title,
  onClick,
  icon: Icon,
  onIconClick = () => {},
  active = false,
  dataQa = "tabBar-tab",
  dropdownOptions = {},
  dropdownItemOnClick = () => {}
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  useEffect(() => {
    if (active && dropdownOpen) {
      setDropdownOpen(false);
    }
  }, [active]);

  const setDropdownState = (open) => {
    if (!Object.keys(dropdownOptions).length) {
      return;
    }
    setDropdownOpen(open);
  };

  return (
    <div className="flex items-center gap-8">
      <div
        className={`font-bold cursor-pointer py-16 ${
          active ? 'border-b border-color-8' : ''
        }`}
        onClick={onClick}
        onMouseEnter={() => setDropdownState(true)}
        onMouseLeave={() => setDropdownState(false)}
        data-qa={dataQa}
      >
        <div
          className={`flex gap-8 items-center justify-between ${
            active ? "color-8" : "color-6_40"
          }`}
        >
          <Text
            textStyle="h3"
            color="text-current"
            dataQa={`${dataQa}-text`}
          >
            {i18nextTranslate(title)}
          </Text>
          {!!Object.keys(dropdownOptions).length && (
            <SingleChevron
              size={xxl ? "14" : "12"}
              direction={DIRECTION.down}
              data-qa={`${dataQa}-arrow`}
            />
          )}
        </div>
        <ConfigDropdown
          options={dropdownOptions}
          selectOption={dropdownItemOnClick}
          dropdownOpen={dropdownOpen}
          dataQa={dataQa}
        />
      </div>
      {Icon ? (
        <Icon
          size={xxl ? "16" : "14"}
          className={`${active ? "color-8" : "color-6_40"} cursor-pointer`}
          onClick={onIconClick}
          data-qa={`${dataQa}-icon`}
        />
      ) : null}
    </div>
  );
};

export default TabBarItem;
