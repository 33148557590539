import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { Button } from 'Components/shared/buttons';
import { CartContext } from 'States/cart/cartState';
import { ROUTES } from 'Router/Routes';

const CartFooter = ({
  totalAmount,
  currency,
  unavailableAssets,
  pendingCartOperation,
  checkoutDisabled
}) => {
  const history = useHistory();
  const { removeFromCart } = useContext(CartContext);

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '140px'
  };

  const proceedToCheckout = () => {
    for (const uniqueAssetId of unavailableAssets) {
      removeFromCart(uniqueAssetId);
    }
    history.push(ROUTES.checkout);
  };

  return (
    <div
      data-qa="cart-footer"
      className="flex items-center justify-between md:justify-end gap-16 md:gap-32 xxl:gap-40 px-16 md:px-0"
    >
      <div className="flex flex-col md:flex-row md:items-center md:gap-16">
        <span className="text-xs xxl:text-sm font-bold uppercase">
          {i18nextTranslate(i18nextKeys.cartTotalAmount)}:
        </span>
        <span
          data-qa="cart-footer-totalAmount"
          className="text-sm xxl:text-base font-light"
        >
          {totalAmount} {currency}
        </span>
      </div>
      <Button
        dataQa="cart-footer-checkoutButton"
        text={i18nextTranslate(i18nextKeys.buttonCheckout)}
        disabled={checkoutDisabled}
        onClick={proceedToCheckout}
        loading={pendingCartOperation}
        className="justify-self-end"
        width={buttonWidth}
      />
    </div>
  );
};

export default CartFooter;
