import React, { useEffect, useCallback, useContext, useMemo, useState } from "react";
import { FieldArray as FinalFieldArray } from "react-final-form-arrays";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { objectArrayToObject } from "Utils/utils";
import useConfigSettings from 'Hooks/useConfigSettings';
import { LangContext } from 'States/lang/langState';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { DropdownField } from "Components/shared/formElements";
import Text from "Components/shared/Text";
import Accordion from "../Accordion";

const TranslationsForm = ({
  fieldArrayName = "Translations",
  defaultValue = {},
  fieldWidth,
  fields = () => null,
  translations = [],
  filterKeys = []
}) => {
  const [languageOptions, setLanguageOptions] = useState([])
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [initialValue, setInitialValue] = useState(null);
  
  const translationsObj = useMemo(
    () => objectArrayToObject(translations, "LanguageCode", filterKeys),
  [translations]);

  const { lang } = useContext(LangContext);

  const {
    data: {
      languages,
      defaultLanguage
    }
  } = useConfigSettings.query({
    select: useCallback(({ Languages = [] }) => {
      let defaultLanguage = null;
      const languages = Languages.map((language) => {
        const translation = i18nextTranslate(i18nextKeys.adminLanguage, { context: language.Code });
        if (language.IsDefault) {
          defaultLanguage = {
            ...language,
            translation
          };
        }
        return {
          ...language,
          translation
        }
      });
      return {
        languages,
        defaultLanguage
      }
    }, [lang]),
  });

  useEffect(() => {
    if (!languages?.length) {
      return;
    }
    let languageOptions = [];
    let initialValue = [];
    for (const {
      Code: languageCode,
      IsDefault,
      translation: languageCodeTranslation
    } of languages) {
      const initialTranslation = translationsObj[languageCode];
      const hasTranslations =
        initialTranslation && typeof initialTranslation === "object"
          ? Object.values(initialTranslation).some(value => value !== null)
          : !!initialTranslation;
      if (IsDefault || hasTranslations) {
        initialValue.push({
          LanguageCode: languageCode,
          ...(initialTranslation || defaultValue)
        });
        continue;
      }
      languageOptions.push({
        value: languageCode,
        label: languageCodeTranslation
      });
    };
    if (languageOptions.length) {
      languageOptions.sort((a, b) => a.label.localeCompare(b.label));
      setLanguageOptions(languageOptions);
      setSelectedLanguage(languageOptions[0]);
    }
    initialValue.sort((a, b) => {
      if (a.LanguageCode === defaultLanguage.Code ||
        b.LanguageCode === defaultLanguage.Code) {
          return a.LanguageCode === defaultLanguage.Code ? -1 : 1;
      } else {
        return 0;
      }
    });
    setInitialValue(initialValue);
  }, [lang])

  const removeLanguageOption = (languageCode) => {
    const updatedLanguageOptions = languageOptions.filter(
      ({ value }) => value !== languageCode
    );
    setSelectedLanguage(updatedLanguageOptions[0] || null);
    setLanguageOptions(updatedLanguageOptions);
  };

  const restoreLanguageOption = (value, label) => {
    const updatedLanguageOptions = [
      ...languageOptions,
      { value, label }
    ];
    updatedLanguageOptions.sort((a, b) => a.label.localeCompare(b.label));
    if (!selectedLanguage) {
      setSelectedLanguage(updatedLanguageOptions[0]);
    }
    setLanguageOptions(updatedLanguageOptions);
  };

  return !initialValue ? (
    <div className="flex justify-center">
      <LoadingSpinner />
    </div>
  ) : (
    <div>
      <div className="flex flex-col gap-8">
        <FinalFieldArray
          name={fieldArrayName}
          initialValue={initialValue}
          subscription={{ value: true }}
        >
          {({ fields: fieldArray }) =>
            fieldArray.map((name, index) => {
              const languageCode = fieldArray.value[index].LanguageCode;
              return languageCode === defaultLanguage.Code ? (
                <div
                  key={name}
                  className="flex flex-col gap-32 xxl:gap-40"
                >
                  <div className="flex flex-col gap-16">
                    <Text dataQa="language-default-title">
                      {i18nextTranslate(i18nextKeys.adminLanguage)} ({defaultLanguage.translation}):
                    </Text>
                    {fields({
                      languageCode,
                      fieldNamePrefix: name,
                      isDefaultLanguage: true
                    })}
                  </div>
                  {languages.length > 1 && (
                    <div
                      className="flex flex-col gap-16 xxl:gap-20"
                      style={{ width: fieldWidth }}
                    >
                      <Text dataQa="translations-title">
                        {i18nextTranslate(i18nextKeys.configTranslationsTitle)}:
                      </Text>
                      <DropdownField
                        label={i18nextTranslate(i18nextKeys.configTranslationsLanguageLabel)}
                        options={languageOptions}
                        input={{
                          value: selectedLanguage,
                          onChange: (value, option) => setSelectedLanguage(option)
                        }}
                        buttonText={i18nextTranslate(
                          i18nextKeys.configTranslationsLanguageButton)
                        }
                        onButtonClick={() => {
                          removeLanguageOption(selectedLanguage.value);
                          fieldArray.insertAt(1, { // Custom mutation
                            ...defaultValue,
                            LanguageCode: selectedLanguage.value
                          })
                        }}
                        className={fieldArray.length > 1 ? "mb-16" : ""}
                        isSearchable
                        withButton
                      />
                    </div>
                  )}
                </div>
              ) : (
                <Accordion
                  key={name}
                  text={i18nextTranslate(i18nextKeys.adminLanguage, { context: languageCode })}
                  fieldWidth={fieldWidth}
                  removeAccordion={(text) => {
                    restoreLanguageOption(languageCode, text);
                    fieldArray.remove(index);
                  }}
                >
                  {fields({
                    languageCode,
                    fieldNamePrefix: name,
                    isDefaultLanguage: false
                  })}
                </Accordion>
              )}
            )
          }
        </FinalFieldArray>
      </div>
    </div>
  );
}

export default TranslationsForm;
