import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Field as FinalField,
  Form as FinalForm,
  FormSpy as FinalFormSpy
} from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { useHistory, useParams } from "react-router-dom";
import api from "Api";
import { ROUTES } from "Router/Routes";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextNamespaces } from "Lang/i18nextConfig";
import { loadTranslations } from "States/lang/langState";
import { DIRECTION } from 'Helpers/icons';
import useConfigSettings from 'Hooks/useConfigSettings';
import useHandleError from "Utils/handleError";
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import {
  PAYMENT_DOCUMENT_TEMPLATE,
  PAYMENT_PROVIDER,
  TENANT_FEATURE
} from "Enums";
import { PAYMENT_METHOD } from "Enums/PaymentMethod";
import { UiContext } from "States/ui/uiState";
import { NumberInput } from "Components/shared/formElements";
import Text from "Components/shared/Text";
import { Arrow } from "Components/shared/symbols";
import { HorizontalRule } from "Components/shared/Tile";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import { SubsectionHeading } from "Features/admin/ConfigManagement/shared";
import { Description, TranslationsForm } from "../../shared/translations";
import Footer from "../Footer";
import {
  AdditionalIdentifiers,
  BankAccount,
  IntermediaryBankAccount
} from "./BankAccount";
import BankCard from "./BankCard";
import CryptoCurrency from "./CryptoCurrency";
import KYCTier from "./KYCTier";
import Templates from "./Templates";

const PaymentMethod = () => {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [providerConfig, setProviderConfig] = useState(null);
  const [isSingleEnabledMethod, setIsSingleEnabledMethod] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingTranslations, setLoadingTranslations] = useState(true);
  const {
    type: paymentMethodType
  } = useParams();
  const history = useHistory();
  const handleError = useHandleError();

  const {
    breakpoints: { xxl }
  } = useContext(UiContext);

  const {
    data: {
      Currency = {},
      Languages
    }
  } = useConfigSettings.query({
    select: useCallback(({ Currency, Languages }) => ({ Currency, Languages }), [])
  });

  useEffect(() => {
    const getLanguages = async (languages) => {
      await loadTranslations(languages, [i18nextNamespaces.admin]);
      setLoadingTranslations(false);
    }
    if (Languages?.length) {
      getLanguages(Languages);
    }
  }, [Languages]);

  const {
    data: isPurchaseEnabled
  } = useFeatureAvailability.query({
    refetchOnMount: false,
    select: useCallback(features => features.some(feature =>
      feature.IsEnabled &&
      (feature.Type === TENANT_FEATURE.purchase || feature.Type === TENANT_FEATURE.customFeaturedAsset)
    ), []),
    onError: (error) => handleError({ error })
  });

  useEffect(() => {
    const getPaymentMethod = async (type) => {
      try {
        if (isPurchaseEnabled) {
          const { value: paymentMethods } = await api.Config.PaymentMethods.getAll();
          const paymentMethod = paymentMethods.find(({ Type }) => Type === paymentMethodType);
          if (paymentMethod.IsEnabled) {
            const enabledMethods = paymentMethods.filter(
              ({ IsEnabled }) => IsEnabled
            );
            setIsSingleEnabledMethod(enabledMethods.length < 2);
          }
          setPaymentMethod(paymentMethod);
        } else {
          const paymentMethod = await api.Config.PaymentMethods.get(type);
          setPaymentMethod(paymentMethod);
        }
        if (type !== PAYMENT_METHOD.BankTransfer) {
          const providerConfig = await api.Config.PaymentProviderConfigurations.get(
            type === PAYMENT_METHOD.CreditCard
              ? PAYMENT_PROVIDER.Payrexx
              : PAYMENT_PROVIDER.Coinify
          );
          setProviderConfig(providerConfig);
        }
        setLoading(false);
      } catch (error) {
        handleError({ error });
      }
    };
    if (paymentMethodType) {
      getPaymentMethod(paymentMethodType)
    } else {
      history.replace(ROUTES.admin.config.purchase);
    }
  }, [isPurchaseEnabled]);

  const openPurchaseConfig = () =>
    history.replace(ROUTES.admin.config.purchase);

  const getDefaultTemplate = (values) => values.templates?.[0]?.FileName;

  const updateTemplates = async (
    currentValues = [],
    initialValues = [],
  ) => {
    const deletedTemplates = initialValues.filter(
      ({ LanguageCode: initialLanguage, FileName: initialFile }) => {
        const currentTemplate = currentValues.find(
          ({ LanguageCode: currentLanguage }) => initialLanguage === currentLanguage
        );
        return !currentTemplate || (currentTemplate.FileName !== initialFile && currentTemplate.FileName === undefined);
      }
    );
    for (const template of deletedTemplates) {
      try {
        await api.Config.Templates.delete(template.LanguageCode, template.Type);
      } catch (error) {
        handleError(error);
      }
    }
    const modifiedTemplates = currentValues.filter(
      ({ FileName }) => FileName !== undefined && typeof FileName !== "string"
    );
    for (const template of modifiedTemplates) {
      try {
        const formData = new FormData();
        formData.append("LanguageCode", template.LanguageCode);
        formData.append("Type", PAYMENT_DOCUMENT_TEMPLATE[paymentMethod.Type]);
        formData.append("File", template.FileName);
        await api.Config.Templates.post(formData);
      } catch (error) {
        handleError(error);
      }
    }
  };

  const save = async (values, form) => {
    setLoading(true);
    const {
      AdditionalBankIdentifiers,
      coinify,
      payrexx,
      templates,
      ...methodParams
    } = values;
    const { dirtyFields, initialValues } = form.getState();
    const {
      templates: templatesDirty,
      "payrexx.ApiUrl": payrexxApiUrlDirty,
      "payrexx.ApiSecret": payrexxApiSecretDirty,
      "coinify.ApiKey": coinifyApiKeyDirty,
      "coinify.ApiUrl": coinifyApiUrlDirty,
      ...dirtyMethodFields
    } = dirtyFields;
    try {
      if (Object.keys(dirtyMethodFields).length) {
        await api.Config.PaymentMethods.patch(
          paymentMethod.Type,
          {
            ...paymentMethod?.Type === PAYMENT_METHOD.BankTransfer && {
              AdditionalBankIdentifiers: {
                Identifiers: [],
                ...AdditionalBankIdentifiers
              }
            },
            ...methodParams,
            "@odata.type": paymentMethod["@odata.type"]
          }
        );
      }
      if (payrexxApiUrlDirty || payrexxApiSecretDirty) {
        await api.Config.PaymentProviderConfigurations.patch(
          PAYMENT_PROVIDER.Payrexx,
          {
            ...payrexx,
            "@odata.type": providerConfig["@odata.type"]
          }
        );
      }
      if (coinifyApiKeyDirty || coinifyApiUrlDirty) {
        await api.Config.PaymentProviderConfigurations.patch(
          PAYMENT_PROVIDER.Coinify,
          {
            ...coinify,
            "@odata.type": providerConfig["@odata.type"]
          }
        );
      }
      if (templatesDirty) {
        await updateTemplates(templates, initialValues.templates);
      }
      form.restart(values);
    } catch (error) {
      handleError({ error });
    }
    setLoading(false);
  };

  const saveAndEnable = async (values, form) => {
    await save({
      ...values,
      IsEnabled: true
    }, form);
    history.push(ROUTES.admin.config.purchase);
  };

  const disable = async () => {
    setLoading(true);
    await api.Config.PaymentMethods.patch(
      paymentMethod.Type,
      {
        IsEnabled: false,
        "@odata.type": paymentMethod["@odata.type"]
      }
    );
    history.push(ROUTES.admin.config.purchase);
  }

  const methodNameTranslation = paymentMethod
    ? i18nextTranslate(i18nextKeys.configPaymentTitle, { context: paymentMethod?.Type })
    : "";
  const fieldWidth = xxl ? "500px" : "404px";
  const textAreaHeight = xxl ? "119px" : "84px";
  const contentWidth = xxl ? "700px" : "600px";

  const insertAtMutator = ([name, index, value], state, { changeValue }) => {
    changeValue(state, name, array => {
      const copy = [...(array || [])];
      copy.splice(index, 0, value);
      return copy;
    })
  };

  const defaultTranslation = {
    Description: null
  };

  const getDescriptionComponent = useCallback(({ isDefaultLanguage, languageCode, fieldNamePrefix }) => {
    const placeholder = i18nextTranslate(
      i18nextKeys.configPaymentTranslationDescriptionPlaceholder,
      { context: paymentMethod.Type, lng: languageCode }
    );
    const language = i18nextTranslate(i18nextKeys.adminLanguage, { context: languageCode });
    const label = isDefaultLanguage
      ? i18nextTranslate(i18nextKeys.configTranslationsDescriptionLabelDefault)
      : i18nextTranslate(
        i18nextKeys.configTranslationsDescriptionLabelLanguage,
        { language }
      );
    return (
      <Description
        fieldName="Description"
        fieldNamePrefix={fieldNamePrefix}
        fieldWidth={fieldWidth}
        textAreaHeight={textAreaHeight}
        label={label}
        placeholder={placeholder}
        dataQa={`description-${languageCode}`}
        required
      />
    )
  }, [paymentMethod]);

  return (
    <FinalForm
      onSubmit={save}
      mutators={{
        ...arrayMutators,
        insertAt: insertAtMutator
      }}
      subscription={{}}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <div className="relative">
            {!paymentMethod || loadingTranslations ? (
              <div className="flex justify-center px-32 py-24">
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <div className="px-32 py-24">
                  <div className="flex flex-col gap-32">
                    <div className="flex gap-16 items-center">
                      <Arrow
                        size={xxl ? "24" : "20"}
                        direction={DIRECTION.left}
                        className="color-6 cursor-pointer"
                        onClick={openPurchaseConfig}
                      />
                      <Text
                        textStyle="h2"
                        dataQa="payment-method-title"
                        uppercase
                      >
                        {i18nextTranslate(i18nextKeys.configPaymentMethod)} - {methodNameTranslation}
                      </Text>
                    </div>
                    <div className="flex flex-col gap-32">
                      <div
                        className="flex flex-col gap-16"
                        style={{ width: contentWidth }}
                      >
                        <SubsectionHeading
                          text={i18nextTranslate(
                            i18nextKeys.configPaymentTranslationDescriptionTitle,
                            { name: methodNameTranslation }
                          )}
                          tooltipText={i18nextTranslate(
                            i18nextKeys.configPaymentTranslationDescriptionTooltip
                          )}
                          className=""
                          dataQa="description"
                        />
                        <TranslationsForm
                          translations={paymentMethod.Translations}
                          defaultValue={defaultTranslation}
                          fieldWidth={fieldWidth}
                          fields={getDescriptionComponent}
                        />
                      </div>
                      <HorizontalRule />
                      <div
                        className="flex flex-col gap-16"
                        style={{ width: fieldWidth }}
                      >
                        <SubsectionHeading
                          text={i18nextTranslate(
                            i18nextKeys.configPaymentLimitTitle
                          )}
                          tooltipText={i18nextTranslate(
                            i18nextKeys.configPaymentLimitTooltip
                          )}
                          className=""
                          dataQa="limit"
                        />
                        <FinalField
                          name="MaxAmount"
                          initialValue={paymentMethod.MaxAmount}
                          validateFields={[]}
                          render={({ input, meta }) => (
                            <NumberInput
                              scale={2}
                              labelText={i18nextTranslate(
                                i18nextKeys.configPaymentLimitLabel
                              )}
                              innerLabelText={Currency.Code}
                              dataQa="limit"
                              showInnerLabel
                              staticInnerLabel
                              fullWidth
                              decimal
                              {...input}
                              {...meta}
                            />
                          )}
                        />
                      </div>
                      <HorizontalRule />
                      {paymentMethod.Type === PAYMENT_METHOD.BankTransfer && (
                        <>
                          <BankAccount
                            account={paymentMethod.BeneficiaryDetails}
                            fieldWidth={fieldWidth}
                            textAreaHeight={textAreaHeight}
                          />
                          <AdditionalIdentifiers
                            identifiers={paymentMethod.AdditionalBankIdentifiers}
                            contentWidth={contentWidth}
                            fieldWidth={fieldWidth}
                            textAreaHeight={textAreaHeight}
                          />
                          <IntermediaryBankAccount
                            account={paymentMethod.IntermediaryBank}
                            contentWidth={contentWidth}
                            fieldWidth={fieldWidth}
                            textAreaHeight={textAreaHeight}
                          />
                        </>
                      )}
                      {paymentMethod.Type === PAYMENT_METHOD.CreditCard && (
                        <BankCard
                          providerConfig={providerConfig}
                          fieldWidth={fieldWidth}
                        />
                      )}
                      {paymentMethod.Type === PAYMENT_METHOD.CryptoCurrency && (
                        <CryptoCurrency
                          providerConfig={providerConfig}
                          fieldWidth={fieldWidth}
                        />
                      )}
                      <HorizontalRule />
                      <Templates
                        contentWidth={contentWidth}
                        fieldWidth={fieldWidth}
                        paymentMethodEnabled={paymentMethod.IsEnabled}
                        paymentMethodName={methodNameTranslation}
                        paymentMethodType={paymentMethod.Type}
                      />
                      <HorizontalRule />
                      <KYCTier
                        currentTier={paymentMethod.KYCTier}
                        contentWidth={contentWidth}
                        fieldWidth={fieldWidth}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
                <FinalFormSpy
                  subscription={{
                    invalid: true,
                    pristine: true,
                    validating: true,
                    values: !paymentMethod.IsEnabled
                  }}
                >
                  {({ invalid, pristine, validating, values }) => paymentMethod.IsEnabled ? (
                    <Footer
                      title={methodNameTranslation}
                      description={i18nextTranslate(
                        i18nextKeys.configFooterMethodEnabledDescription
                      )}
                      tooltipText={i18nextTranslate(
                        i18nextKeys.configPaymentDescription,
                        { context: paymentMethod.Type }
                      )}
                      primaryButtonText={i18nextTranslate(i18nextKeys.configUpdateSettings)}
                      primaryButtonWidth={{
                        xxl: "210px",
                        default: "200px"
                      }}
                      onPrimaryButtonClick={handleSubmit}
                      primaryButtonDisabled={loading || invalid || pristine || validating}
                      primaryButtonLoading={loading}
                      secondaryButtonText={i18nextTranslate(i18nextKeys.configDisableMethod)}
                      secondaryButtonTooltipText={
                        isSingleEnabledMethod
                          ? i18nextTranslate(i18nextKeys.configPaymentTooltip)
                          : null
                      }
                      onSecondaryButtonClick={disable}
                      secondaryButtonDisabled={loading || isSingleEnabledMethod}
                      dataQa="method"
                    />
                  ) : (
                    <Footer
                      title={methodNameTranslation}
                      description={i18nextTranslate(
                        i18nextKeys.configPaymentFooterDescription
                      )}
                      tooltipText={i18nextTranslate(
                        i18nextKeys.configPaymentDescription,
                        { context: paymentMethod.Type }
                      )}
                      primaryButtonText={i18nextTranslate(i18nextKeys.configEnableMethod)}
                      primaryButtonWidth={{
                        xxl: "383px",
                        default: "281px"
                      }}
                      onPrimaryButtonClick={() => saveAndEnable(values, form)}
                      primaryButtonDisabled={loading || paymentMethod?.IsEnabled || invalid ||
                        validating || !getDefaultTemplate(values)}
                      primaryButtonLoading={loading}
                      secondaryButtonText={i18nextTranslate(i18nextKeys.configSaveSettings)}
                      onSecondaryButtonClick={handleSubmit}
                      secondaryButtonDisabled={loading || invalid || pristine || validating}
                      dataQa="method"
                    />
                  )}
                </FinalFormSpy>
              </>
            )}
          </div>
        </form>
      )}
    />
  );
};

export default PaymentMethod;
