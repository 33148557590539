import React, { memo } from "react";
import { Field as FinalField } from "react-final-form";
import { composeValidators } from "revalidate";
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { isMaxLengthExceeded, isRequiredIfWithMessage } from "Utils/validators";
import { FormField } from "Components/shared/formElements";

const Description = ({
  fieldName = "Description",
  fieldNamePrefix,
  fieldWidth,
  textAreaHeight,
  maxLength = 60,
  label,
  placeholder,
  dataQa = "description",
  required = false,
  validateFields = []
}) => {
  const validator = composeValidators(
    isRequiredIfWithMessage(
      () => required,
      i18nextTranslate(i18nextKeys.configTranslationsField)
    ),
    isMaxLengthExceeded(maxLength)
  )();

  return (
    <FinalField
      name={`${fieldNamePrefix}.${fieldName}`}
      label={label}
      subscription={{
        active: true,
        error: true,
        touched: true,
        value: true
      }}
      validate={required ? validator : null}
      validateFields={validateFields}
      render={(props) => (
        <FormField
          dataQa={dataQa}
          maxLength={maxLength}
          placeholder={placeholder}
          style={{
            lineHeight: "1.194rem",
            marginBottom: "0.125rem"
          }}
          containerStyle={{ width: fieldWidth }}
          height={textAreaHeight}
          showNumberOfChars
          multiline
          {...props}
        />
      )}
      keepDirtyOnReinitialize
      required={required}
    />
  );
};

export default memo(Description);
