import axios from "axios";
import { HEX_OPACITY } from "./HexOpacity";

const instance = axios.create();
instance.defaults.headers.common = {};

const BlobFileName = "colors.json"

const colorsParams = [
  {
    "derivatives": []
  },
  {
    "opacities": [50, 60],
    "derivatives": [-80]
  },
  {
    "opacities": [40],
    "derivatives": [-20, -10, -5, -2, 2, 5, 10],
    "pseudoClasses": ["hover"]
  },
  {
    "opacities": [3, 5, 7, 10, 15, 20, 25, 40, 50],
    "derivatives": [-20, -40, 2],
    "pseudoClasses": ["hover", "active"]
  },
  {
    "opacities": [25],
    "pseudoClasses": ["active"]
  },
  {
    "opacities": [40]
  },
  {
    "opacities": [20]
  },
  {
    "opacities": [50, 60, 80],
    "pseudoClasses": ["hover"]
  },
  {
    "derivatives": [-10, -5, 10]
  },
  {
    "derivatives": [40],
    "opacities": [40]
  }
];

const DEFAULT_COLORS = [
  {
    name: "red",
    color: "#E16565"
  },
  {
    name: "lightRed",
    color: "#F6D1D1"
  },
  {
    name: "grey",
    color: "#A0AEC0",
    pseudoClasses: ["disabled"]
  },
  {
    name: "lightGrey",
    derivatives: [-10, -5],
    color: "#D3D3D3"
  },
  {
    name: "white",
    color: "#FFFFFF",
    pseudoClasses: ["disabled"]
  },
  {
    name: "green",
    color: "#3CBB6D"
  }
];

const PROPERTIES_FOR_COLOR = {
  bg: "background-color",
  color: "color",
  "border-color": "border-color",
};

function lightenColor(color, percent) {
  const num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    B = (num >> 8 & 0x00FF) + amt,
    G = (num & 0x0000FF) + amt;
  return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
}

const mergeColorsAndParams = (pureColors) => {
  return pureColors.map((color, index) => {
    return {
      ...color,
      ...colorsParams[index]
    }
  })
}

function getColorVariableName({ name, index, derivative = null, opacity = null}) {
  return `--color-${name || index + 1}${derivative ? `-${derivative}` : ""}${opacity ? `_${opacity}` : ""}`;
}

function getColorVariables(dynamicColors) {
  let colorVariables = "";
  let generatedClassNames = "";

  const setColorStrings = ({
    color,
    index,
    name,
    derivative,
    opacity,
    pseudoClasses
  }) => {
    const hexOpacity = !!opacity ? HEX_OPACITY[opacity] : null;
    const varName = getColorVariableName({ name, index, derivative, opacity });
    const resultColor = !hexOpacity ? color : `${color}${hexOpacity}`;
    colorVariables += `${varName}: ${resultColor};`;
    addGeneratedClassName({
      name: varName.replace(/^(--color-)/, ''),
      color: resultColor,
      pseudoClasses
    });
  };

  const addGeneratedClassName = ({ name, color, pseudoClasses = [] }) => {
    for (let key in PROPERTIES_FOR_COLOR) {
      generatedClassNames += `
        .${key}-${name} {
          ${PROPERTIES_FOR_COLOR[key]}: ${color};
        }
      `;
      if (pseudoClasses.length) {
        for (const pseudoClass of pseudoClasses) {
          generatedClassNames += `
            .${pseudoClass}\\:${key}-${name}:${pseudoClass} {
              ${PROPERTIES_FOR_COLOR[key]}: ${color};
            }
          `;
        }
      }
    }
  };

  const setColor = (color, index) => {
    setColorStrings({ ...color, index});

    if (color.opacities?.length) {
      color.opacities.forEach(opacity => {
        setColorStrings({ ...color, index, opacity});
      })
    }

    if (color.derivatives?.length) {
      color.derivatives.forEach((derivative) => {
        setColorStrings({
          index,
          color: lightenColor(color.color, derivative),
          name: color.name,
          derivative,
          pseudoClasses: color.pseudoClasses
        });

        if (color.opacities?.length) {
          color.opacities.forEach(opacity => {
            setColorStrings({
              index,
              color: lightenColor(color.color, derivative),
              name: color.name,
              derivative,
              opacity
            });
          })
        }
      });
    }
  };

  dynamicColors.forEach((color, index) => {
    setColor(color, index);
  });
  DEFAULT_COLORS.forEach((color, index) => {
    setColor(color, index);
  });
  return { colorVariables, generatedClassNames };
}

export default async function generateColorStyles(blobUrl) {
  const head = document.head;
  const style = document.createElement("style");
  const { data } = await instance.get(`${blobUrl}/${BlobFileName}`);
  if (!data || !data.colors) {
    return;
  }
  const colors = getColorVariables(mergeColorsAndParams(data.colors));

  style.innerHTML = `
    :root {
      ${colors.colorVariables}
    }
    
    ${colors.generatedClassNames}
  `;
  head.appendChild(style);
}
