import React from 'react';
import { copyToClipboardAndNotify } from 'Utils/utils';
import i18nextTranslate from 'Lang/i18nextTranslate';
import Tooltip from 'Components/shared/Tooltip';

const BankDetailsInfoBox = ({ value, tooltipDescription, translationKey }) => {
  return value ? (
    <div
      data-qa={`checkout-bankInfo-details-${translationKey}-wrapper`}
      onClick={() =>
        copyToClipboardAndNotify(value, i18nextTranslate(translationKey))
      }
      className="cursor-pointer"
    >
      <div className="flex items-center">
        <span className={'mr-8 color-8 font-bold text-xs xxl:text-sm'}>
          {i18nextTranslate(translationKey)}
        </span>

        {tooltipDescription && (
          <Tooltip
            text={tooltipDescription}
            dataQa={`checkout-bankInfo-details-${translationKey}`}
            textClassName="text-xs"
            useIcon
          />
        )}
      </div>
      <p className="font-normal text-sm text-left xxl:text-base">{value}</p>
    </div>
  ) : null;
};

export default BankDetailsInfoBox;
