import React, { useContext, useState } from "react";
import { useAmplitude } from "react-amplitude-hooks";
import api from "Api";
import { UiContext } from "States/ui/uiState";
import CollectMethod from 'Enums/CollectMethod';
import OrderStatusEnum from 'Enums/OrderStatus';
import PaymentMethodEnum from 'Enums/PaymentMethod';
import formatDateTime from "Utils/formatDateTime";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { Chip } from 'Components/shared/Chip';
import {
  Tile,
  TileInfoCard,
  HorizontalRule
} from "Components/shared/Tile";
import { AdditionalDataModal, StatusUpdater } from 'Features/admin/shared';
import WalletAddressModal from './WalletAddressModal';

const OrderInformation = ({ order, setOrder, isAdmin }) => {
  const { logEvent } = useAmplitude();
  const {
    breakpoints: { md }
  } = useContext(UiContext);
  const [showAdditionalDataModal, setShowAdditionalDataModal] = useState(false);
  const [showWalletAddressModal, setShowWalletAddressModal] = useState(false);

  const onOrderStatusChange = (Status) => {
    setOrder({ ...order, Status });
    logEvent("Order status changed");
  };

  const onAdditionalDataChange = (AdditionalData) => {
    setOrder({ ...order, AdditionalData });
    logEvent("Add details order");
  };

  return (
    <>
      <Tile title={i18nextTranslate(i18nextKeys.orderInformation)}>
        <div className="flex flex-col gap-24">
          <div className="grid gap-20 grid-cols-1 md:grid-cols-3">
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.commonReferenceCode)}
              text={order.ReferenceCode}
              dataQa="order-referenceCode"
              copyOnClick
            />
            <TileInfoCard
              dataQa="order-id"
              title={i18nextTranslate(i18nextKeys.commonOrderId)}
              text={order.Id}
              showTooltip
              copyOnClick
            />
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.commonDate)}
              text={formatDateTime(order.ReceivedOn)}
              textProps={{ uppercase: true }}
              dataQa="order-date"
              copyOnClick
            />
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.orderPaidVia)}
              text={PaymentMethodEnum.enumToFriendlyString(order.PaymentMethod)}
              dataQa="order-paidVia"
            />
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.orderReceivingMethod)}
              text={CollectMethod.enumToFriendlyString(order.CollectMethod)}
              dataQa="order-receivingMethod"
            />
            {!isAdmin && (
              <TileInfoCard
                title={i18nextTranslate(i18nextKeys.orderStatus)}
                text={
                  <Chip text={OrderStatusEnum.enumToFriendlyString(order.Status)} />
                }
                dataQa="order-status"
              />
            )}
          </div>
          {isAdmin && (
            <StatusUpdater
              id={order.Id}
              status={order.Status}
              statusEnum={OrderStatusEnum}
              updateRemoteStatus={api.PurchaseOrder.updateDelta}
              updateLocalStatus={onOrderStatusChange}
              label={i18nextTranslate(i18nextKeys.orderStatus)}
              errorMessage={i18nextTranslate(
                i18nextKeys.errorPurchaseManagementDetailsOrderStatusUpdateError
              )}
              dataQa="order-status-update"
            />
          )}
          <HorizontalRule />
          <div
            className="grid col-gap-24 row-gap-24"
            style={{ gridTemplateColumns: md ? "repeat(2, 1fr)" : "1fr" }}
          >
            <TileInfoCard
              dataQa="order-tokenCard"
              title={i18nextTranslate(i18nextKeys.enumCollectMethodTokenCardName)}
              text={(order.CollectMethod === CollectMethod.values.ExistingTokenCard && order.TokenCard) ||
                i18nextTranslate(
                  order.CollectMethod === CollectMethod.values.TokenCard
                    ? i18nextKeys.commonYes
                    : i18nextKeys.commonNo
                )
              }
            />
            <TileInfoCard
              dataQa="order-walletAddress"
              title={i18nextTranslate(i18nextKeys.commonWalletAddress)}
              text={order.TargetEthAddress}
              showTooltip={!md}
              showEditButton={isAdmin}
              onEdit={() => setShowWalletAddressModal(true)}
              editDisabled={order.Status === OrderStatusEnum.values.Sent}
              copyOnClick
            />
          </div>
          {isAdmin && (
            <TileInfoCard
              title={i18nextTranslate(i18nextKeys.commonAdditionalData)}
              text={order.AdditionalData}
              textClassName="break-all"
              textProps={{
                showNumberOfLines: 3,
                showFullTextOnClick: true
              }}
              showEditButton={isAdmin}
              onEdit={() => setShowAdditionalDataModal(true)}
            />
          )}
        </div>
      </Tile>

      {isAdmin && (
        <>
          <AdditionalDataModal
            id={order.Id}
            additionalData={order.AdditionalData}
            updateLocalData={onAdditionalDataChange}
            updateRemoteData={api.PurchaseOrder.updateDelta}
            show={showAdditionalDataModal}
            close={() => setShowAdditionalDataModal(false)}
          />
          <WalletAddressModal
            order={order}
            setOrder={setOrder}
            show={showWalletAddressModal}
            close={() => setShowWalletAddressModal(false)}
          />
        </>
      )}
    </>
  );
};

export default OrderInformation;
