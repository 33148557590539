import React, { useContext } from "react";
import { Form as FinalForm, Field as FinalField } from "react-final-form";
import { COLLECT_METHOD } from 'Enums/CollectMethod';
import { Button } from 'Components/shared/buttons';
import { CheckboxWithText, FormField } from "Components/shared/formElements";
import Modal from "Components/shared/Modal";
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import { UiContext } from 'States/ui/uiState';

let formRef;

const ExistingTokenCardModal = ({
  show,
  close,
  select,
  content = {},
  inputRegex
}) => {
  const {
    breakpoints: { md, xxl },
  } = useContext(UiContext);

  const resetFormAndClose = () => {
    formRef.restart();
    close();
  };

  const confirmTokenCard = async ({ tokenCardSerialNumber }) => {
    select({
      collectionMethodType: COLLECT_METHOD.ExistingTokenCard,
      tokenCardSerialNumber
    });
  };

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '142px'
  };

  const fieldWidth = {
    width: xxl ? "500px" : md ? "404px" : "100%"
  }

  const validator = (value) => {
    if (!value) {
      return i18nextTranslate(
        i18nextKeys.validatorMessage,
        { field: i18nextTranslate(i18nextKeys.collectionTokenCardModalLabel) }
      );
    }
    if (!inputRegex) {
      return;
    }
    try {
      const regex = new RegExp(inputRegex);
      const match = value.match(regex);
      if (match) {
        return;
      } else {
        return i18nextTranslate(i18nextKeys.collectionTokenCardModalError);
      }
    } catch (error) {
      return i18nextTranslate(i18nextKeys.errorDefaultMessage);
    }
  };

  return (
    <Modal
      show={show}
      close={close}
      dataQa="tokenCard-existing-modal"
      shouldShowCloseButton={close}
    >
      <FinalForm
        onSubmit={confirmTokenCard}
        subscription={{
          invalid: true,
          pristine: true
        }}
      >
        {({ handleSubmit, form, pristine, invalid }) => {
          formRef = form;
          return (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col py-20 px-16 md:px-24 md:pt-24 md:pb-32"
            >
              <h2 className="color-8 font-extrabold text-xsm uppercase mb-16 xl:mb-24 xxl:text-base">
                {i18nextTranslate(i18nextKeys.collectionTokenCardModalTitle)}
              </h2>
              <p
                data-qa="checkout-collectionMethod-detailsModal-text"
                className="mb-24 color-8 font-normal text-sm text-left xxl:text-base"
              >
                {content.description}
              </p>
              <FinalField
                name="tokenCardSerialNumber"
                subscription={{
                  active: true,
                  error: true,
                  touched: true,
                  value: true
                }}
                placeholder={i18nextTranslate(i18nextKeys.collectionTokenCardModalLabel)}
                label={i18nextTranslate(i18nextKeys.collectionTokenCardModalLabel)}
                style={{ ...fieldWidth }}
                validate={validator}
                component={FormField}
                dataQa="tokenCard-existing-serial"
                required
              />
              <CheckboxWithText
                name="disclaimer"
                text={content.disclaimer}
                textStyle={
                  md
                    ? {}
                    : {
                      WebkitMaskImage:
                        'linear-gradient(180deg, #000 68%, transparent)',
                      maskImage:
                        'linear-gradient(180deg, #000 68%, transparent)',
                      overflowY: 'scroll',
                      height: 112,
                      paddingBottom: 25,
                    }
                }
                className="mt-16"
                dataQa="tokenCard-existing-disclaimer"
                required
              />
              <div className="flex justify-between w-full mt-24">
                <Button
                  text={i18nextTranslate(i18nextKeys.buttonClose)}
                  onClick={resetFormAndClose}
                  dataQa="tokenCard-existing-cancel"
                  width={buttonWidth}
                  // disabled={savingWallet}
                  secondary
                />
                <Button
                  type="submit"
                  disabled={pristine || invalid}
                  // loading={savingWallet}
                  text={i18nextTranslate(i18nextKeys.buttonConfirm)}
                  dataQa="tokenCard-existing-confirm"
                  width={buttonWidth}
                />
              </div>
            </form>
          );
        }}
      </FinalForm>
    </Modal>
  )
};

export default ExistingTokenCardModal;
