import React, { useContext, useEffect, useState } from 'react';
import { LangContext, getTranslationNameSpaces, loadTranslations } from 'States/lang/langState';
import Select from 'react-select';
import { DropdownIndicator } from 'Components/shared/formElements/Dropdown/DropdownField';
import { AuthContext } from 'States/auth/authState';
import useConfigSettings from 'Hooks/useConfigSettings';
import { useAmplitude } from 'react-amplitude-hooks';

const LanguageSwitcher = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const { lang, setLang } = useContext(LangContext);
  const { user, isAuthenticated, isAdmin } = useContext(AuthContext);
  const { logEvent } = useAmplitude((inheritedProps) => ({
    ...inheritedProps,
  }));
  const { data: settings } = useConfigSettings.query({});

  useEffect(() => {
    setSelectedLanguage({ Code: lang });
  }, [lang]);

  if (settings?.Languages?.length === 0 || !selectedLanguage) return null;

  const options = settings?.Languages?.sort((a, b) => a.Code.localeCompare(b.Code));

  const onLanguageSelectionChange = ({ Code }) => {
    logEvent("Language changed", { "Language selected": Code });
    setSelectedLanguage({ Code });
    setLang(Code, user);
  };

  const preloadTranslations = () => loadTranslations(
    settings.Languages,
    getTranslationNameSpaces({ isAuthenticated, isAdmin })
  );

  return (
    <div
      className="flex-shrink-0"
      data-qa="language-switcher-container"
      onMouseOver={preloadTranslations}
      onTouchStart={preloadTranslations}
    >
      <Select
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        isDisabled={false}
        menuPlacement="bottom"
        maxMenuHeight={200}
        isSearchable={false}
        options={options}
        getOptionLabel={(option) => option.Code}
        getOptionValue={(option) => option.Code}
        value={selectedLanguage}
        onChange={onLanguageSelectionChange}
        styles={{
          control: (
            { color, borderColor, boxShadow, ...provided },
            { theme, menuIsOpen }
          ) => ({
            ...provided,
            gap: '2px',
            cursor: 'pointer',
            padding: 0,
            minHeight: 0,
            color: 'var(--color-7)',
            border: 0,
            borderColor: theme.colors.neutral20,
            background: 'transparent',
            backgroundColor:
                menuIsOpen
                  ? 'var(--color-9-10)'
                  : 'var(--color-9)',
          }),

          dropdownIndicator: (provided, { selectProps }) => ({
            transform: selectProps.menuIsOpen ? 'scale(1, -1)' : 'scale(1)',
          }),

          menu: (provided) => ({
            ...provided,
            margin: 0,
          }),

          menuList: (provided) => ({
            width: 'min-content',
            backgroundColor: "var(--color-9-10)",
            borderRadius: '4px',
            paddingRight: '10px', // not possible to override top/bottom padding
            paddingLeft: '10px',
            ...provided,
          }),
          option: ({ cursor, ...provided }, { isFocused, isSelected }) => ({
            color: 'var(--color-10)',
            fontSize: '14px',
            lineHeight: '19px',
            marginTop: '4px',
            marginBottom: '4px',
            textAlign: 'center',
            borderTop: 'none',
            cursor: 'pointer',
            overflow: 'hidden',
            textTransform: 'uppercase',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            backgroundColor: (isSelected && isFocused) || isFocused ? 'var(--color-9--5)' : 'var(--color-9-10)',
          }),

          singleValue: ({ color, ...provided }, { isDisabled }) => ({
            ...provided,
            gridColumnStart: 2,
            color: isDisabled ? 'var(--color-10)' : 'inherit',
            fontSize: '13px',
            fontWeight: '800',
            lineHeight: '18px',
            textTransform: 'uppercase',
            margin: 0
          }),

          valueContainer: (provided) => ({
            ...provided,
            padding: 0,
          }),
        }}
      />
    </div>
  );
};

export default LanguageSwitcher;
