import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation, matchPath } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import { Form as FinalForm } from 'react-final-form';
import { useAmplitude } from 'react-amplitude-hooks';
import api from 'Api';
import { ROUTES } from 'Router/Routes';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import useHandleError from 'Utils/handleError';
import { AuthContext } from 'States/auth/authState';
import { COLLECT_METHOD } from 'Enums/CollectMethod';
import { Button } from 'Components/shared/buttons';
import { CheckboxWithText } from 'Components/shared/formElements';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import UserDetailsForm from 'Components/UserDetails/UserDetailsForm';

const CheckoutCustomerDetails = () => {
  const {
    user: {
      profile: { sub },
    },
  } = useContext(AuthContext);
  const handleError = useHandleError();
  const history = useHistory();
  const location = useLocation();
  const { logEvent } = useAmplitude();

  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      !location.state?.collectionMethodType &&
      matchPath(location.pathname, {
        path: ROUTES.checkoutCustomerDetails,
        exact: true,
      })
    ) {
      history.replace(ROUTES.checkout);
    } else {
    }
  }, []);

  const collectionMethodType = location.state?.collectionMethodType;
  const collectionMethodAddress = location.state?.collectionMethodAddress;
  const tokenCardSerialNumber = location.state?.tokenCardSerialNumber;
  const isPhysicalShipment = collectionMethodType === COLLECT_METHOD.TokenCard;

  useEffect(() => {
    const getUserInfoAndAddress = async () => {
      try {
        const user = await api.User.get(sub);
        setUser(user);
        setIsLoading(false);
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorCartCustomerDetailsLoadingError
        );
        handleError({ error, message });
      }
    };
    getUserInfoAndAddress();
  }, []);

  const submitCustomerDetails = async (values) => {
    const {
      invoiceAddressAsShippingAddress,
      EmailAddress,
      ShippingAddress,
      ...HomeAddress
    } = values;

    if (!user.HomeAddressId) {
      await api.Addresses.create(HomeAddress);
    }
    if (!user.PhoneNumber) {
      await api.User.updateDelta(user.Id, { PhoneNumber: HomeAddress.PhoneNumber });
    }

    logEvent("Invoice details confirmed");
    history.push({
      pathname: ROUTES.checkoutOrderSummary,
      state: {
        collectionMethodAddress,
        collectionMethodType,
        tokenCardSerialNumber,
        invoiceAddress: HomeAddress,
        ...!invoiceAddressAsShippingAddress && {
          shippingAddress: ShippingAddress
        }
      }
    });
  };

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '156px'
  };

  return (
    <div className="flex flex-col items-center w-full">
      <AnimateHeight duration={750} height={!isLoading ? 0 : 'auto'}>
        <LoadingSpinner />
      </AnimateHeight>

      <AnimateHeight
        duration={750}
        height={isLoading ? 0 : 'auto'}
        className="w-full"
      >
        <FinalForm
          onSubmit={submitCustomerDetails}
          subscription={{
            invalid: true,
            values: true
          }}
          render={({ handleSubmit, invalid, values }) => (
            <form
              id="checkoutDetailsForm"
              className="w-full flex flex-col"
              onSubmit={handleSubmit}
            >
              <UserDetailsForm
                user={user}
                borderRadius={
                  isPhysicalShipment && !values.invoiceAddressAsShippingAddress
                    ? "rounded-t"
                    : "rounded"
                }
                dataQa="shop-invoiceAddress"
                title={i18nextTranslate(i18nextKeys.commonAddressInvoice)}
                firstStepTitle={i18nextTranslate(i18nextKeys.commonInfoPersonal)}
                secondStepTitle={i18nextTranslate(i18nextKeys.commonAddressInvoice)}
                customField={isPhysicalShipment && (
                  <CheckboxWithText
                    name="invoiceAddressAsShippingAddress"
                    text={i18nextTranslate(i18nextKeys.checkoutCustomerDetailsFormInvoiceInfo)}
                    className="items-center"
                    dataQa="address-shipping-add"
                    checked
                  />
                )}
                disableEmail={!!user.EmailAddress}
                disablePhoneNumber={!!user.PhoneNumber}
                includeEmail
                includePhoneNumber
              />
              {isPhysicalShipment && (
                <AnimateHeight
                  height={!values.invoiceAddressAsShippingAddress ? 'auto' : 0}
                  duration={750}
                >
                  {!values.invoiceAddressAsShippingAddress && (
                    <>
                      <hr className="border-color-5" />
                      <UserDetailsForm
                        borderRadius="rounded-b"
                        dataQa="shop-shippingAddress"
                        title={i18nextTranslate(i18nextKeys.commonAddressShipping)}
                        firstStepTitle={i18nextTranslate(i18nextKeys.commonInfoPersonal)}
                        secondStepTitle={i18nextTranslate(i18nextKeys.commonAddressShipping)}
                        fieldGroup="ShippingAddress"
                        required={!values.invoiceAddressAsShippingAddress}
                        requiredToggleName="invoiceAddressAsShippingAddress"
                      />
                    </>
                  )}
                </AnimateHeight>
              )}
              <footer className="flex flex-wrap items-center justify-between w-full pt-24">
                <Button
                  dataQa="checkout-backButton"
                  text={i18nextTranslate(i18nextKeys.buttonBack)}
                  onClick={() => history.replace(ROUTES.checkoutCollectionMethod)}
                  width={buttonWidth}
                  tertiary
                />
                <Button
                  dataQa="checkout-submitButton"
                  type="submit"
                  text={i18nextTranslate(i18nextKeys.buttonContinue)}
                  width={buttonWidth}
                  disabled={invalid}
                />
              </footer>
            </form>
          )}
        />
      </AnimateHeight>
    </div>
  );
};

export default CheckoutCustomerDetails;
