import { combineValidators } from 'revalidate';
import { isString } from 'Utils/validators';

export default function landingPageConfigValidator() {
  return combineValidators({
    primaryHeaderText: isString('primaryHeaderText'),
    secondaryHeaderText: isString('secondaryHeaderText'),
    subHeaderText: isString('subHeaderText')
  });
}
