import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { LogOnMount } from 'react-amplitude-hooks';
import api from 'Api';
import Fade from 'Components/shared/Fade';
import { Button } from 'Components/shared/buttons';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { CartContext } from 'States/cart/cartState';
import { UiContext } from 'States/ui/uiState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import useHandleError from 'Utils/handleError';
import codeToCountry from 'Utils/country-codes';
import { COLLECT_METHOD } from 'Enums/CollectMethod';
import { STORAGE_KEYS } from 'Enums';
import { ROUTES } from 'Router/Routes';
import AmountAndReference from '../AmountAndReference';

const Completion = ({ restrictedMode = false }) => {
  const handleError = useHandleError();
  const history = useHistory();
  const location = useLocation();
  const { emptyCart } = useContext(CartContext);
  const {
    breakpoints: { md }
  } = useContext(UiContext);

  const [loading, setLoading] = useState(true);
  const [shippingAddress, setShippingAddress] = useState(null);

  const order = location?.state?.order || {
    ...JSON.parse(sessionStorage.getItem(STORAGE_KEYS.order)),
  };

  useEffect(() => {
    if (order && Object.keys(order).length !== 0) {
      finishCheckout();
    } else {
      history.replace(
        restrictedMode
          ? ROUTES.featuredAssetBase
          : {
              pathname: ROUTES.cart,
              state: {},
            }
      );
    }
  }, []);

  const finishCheckout = async () => {
    try {
      setLoading(true);
      sessionStorage.removeItem(STORAGE_KEYS.order);
      if (order.CollectMethod === COLLECT_METHOD.TokenCard) {
        const { ShippingAddress } = await api.PurchaseOrder.details(order.Id, [
          'ShippingAddress',
        ]);
        setShippingAddress(ShippingAddress);
      }
      emptyCart();
      setLoading(false);
    } catch (error) {
      const message = i18nextTranslate(
        i18nextKeys.errorCartCheckoutPaymentOrderUnsuccessful
      );
      handleError({ error, message });
    }
  };

  const toOrdersList = () => history.replace(ROUTES.orders);
  const toHomePage = () => history.replace(ROUTES.base);

  const buttonWidth = {
    xxl: '190px',
    md: '170px',
    default: '156px'
  };

  return (
    <LogOnMount eventType="Order completed">
      <Fade show={true} className='flex justify-center'>
        {loading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div
            data-qa="checkout-completion-wallet"
            className="flex flex-col items-center color-8 text-center mx-16 sm:mx-0"
            style={{
              maxWidth: md ? '50%' : '100%'
            }}
          >
            <h1 className="text-xl xxl:text-2xl text-center font-semibold color-4 mt-24 md:mt-40 mb-24 md:mb-12">
              {i18nextTranslate(
                i18nextKeys.checkoutCompletionHeader
              )}
            </h1>
            <p className="text-sm xxl:text-base font-bold mb-16">
              {i18nextTranslate(shippingAddress
                ? i18nextKeys.checkoutCompletionAddressTokenCard
                : i18nextKeys.checkoutCompletionAddressWallet
              )}
            </p>
            {shippingAddress ? (
              <ul className="text-sm xxl:text-base mb-24 md:mb-40">
                <li>
                  {shippingAddress.FirstName} {shippingAddress.LastName}
                </li>
                <li>{shippingAddress.AddressLine}</li>
                <li>
                  {shippingAddress.ZipCode} {shippingAddress.City}
                </li>
                <li>{shippingAddress.State}</li>
                <li>
                  {i18nextTranslate(codeToCountry[shippingAddress.Country])}
                </li>
              </ul>
            ) : (
              <p className='text-sm xxl:text-base mb-24 md:mb-40'>
                {order?.TargetEthAddress}
              </p>
            )}
            <AmountAndReference
              amount={order.AmountDue}
              currency={order.AmountDueCurrency}
              reference={order.ReferenceCode}
              className="grid-cols-2"
            />
            <div className="flex justify-center items-center gap-16 mt-20 md:mt-40">
              {!restrictedMode && (
                <Button
                  dataQa="button-home"
                  text={i18nextTranslate(i18nextKeys.buttonHome)}
                  width={buttonWidth}
                  onClick={toHomePage}
                  tertiary
                />
              )}
              <Button
                dataQa="button-orders"
                text={i18nextTranslate(i18nextKeys.checkoutFinishButton)}
                width={buttonWidth}
                onClick={toOrdersList}
                secondary
              />
            </div>
          </div>
        )}
      </Fade>
    </LogOnMount>
  );
};

export default Completion;
