import i18nextTranslate from "../Lang/i18nextTranslate";
import {i18nextKeys} from "../Lang/i18nextKeys";

export const COLLECT_METHOD = {
  None: 'None',
  TokenCard: 'TokenCard',
  ExistingTokenCard: 'ExistingTokenCard',
  OwnWallet: 'OwnWallet',
  OwnNonSignableWallet: 'OwnNonSignableWallet',
  OneTimeWallet: 'OneTimeWallet'
};

export const DISPLAY_ORDER = {
  TokenCard: 1,
  ExistingTokenCard: 2,
  OwnWallet: 3,
  OwnNonSignableWallet: 4,
  OneTimeWallet: 5
};

const enumToFriendlyString = (e) => {
  const {
    TokenCard,
    ExistingTokenCard,
    OwnWallet,
    OwnNonSignableWallet,
    OneTimeWallet
  } = COLLECT_METHOD;
  switch (e) {
    case TokenCard: {
      return i18nextTranslate(i18nextKeys.enumCollectMethodTokenCardName);
    }
    case ExistingTokenCard: {
      return i18nextTranslate(i18nextKeys.collectionTitle, { context: e });
    }
    case OwnWallet: {
      return i18nextTranslate(i18nextKeys.enumCollectMethodOwnWalletName);
    }
    case OwnNonSignableWallet:
    case OneTimeWallet: {
      return i18nextTranslate(i18nextKeys.enumCollectMethodOwnWalletNsName);
    }
    default: {
      return i18nextTranslate(i18nextKeys.enumNone);
    }
  }
};

const friendlyStringToEnum = (str) => {
  const { TokenCard, ExistingTokenCard, OwnWallet, OwnNonSignableWallet } = COLLECT_METHOD;
  switch (str) {
    case i18nextTranslate(i18nextKeys.enumCollectMethodTokenCardName): {
      return TokenCard;
    }
    case i18nextTranslate(i18nextKeys.collectionTitle, { context: COLLECT_METHOD.ExistingTokenCard }): {
      return ExistingTokenCard;
    }
    case i18nextTranslate(i18nextKeys.enumCollectMethodOwnWalletName): {
      return OwnWallet;
    }
    case i18nextTranslate(i18nextKeys.enumCollectMethodOwnWalletNsName): {
      return OwnNonSignableWallet;
    }
    default: {
      return COLLECT_METHOD[str] || COLLECT_METHOD.None;
    }
  }
};

const CollectMethod = {
  values: COLLECT_METHOD,
  order: DISPLAY_ORDER,
  enumToFriendlyString,
  friendlyStringToEnum,
};

export default CollectMethod;
