import React, { useReducer, createContext } from 'react';
import axios from 'axios';
import langReducer from './langReducer';
import { SET_LANG } from './langTypes';
import i18next, { i18nextNamespaces } from 'Lang/i18nextConfig';
import api from 'Api';
import Urls from 'Utils/urls';
import useHandleError from 'Utils/handleError';
import { i18nextKeys } from 'Lang/i18nextKeys';
import anonymous_en from 'Lang/resources/en/anonymous.json';
import anonymous_de from 'Lang/resources/de/anonymous.json';
import anonymous_fr from 'Lang/resources/fr/anonymous.json';
import anonymous_es from 'Lang/resources/es/anonymous.json';
import authenticated_en from 'Lang/resources/en/authenticated.json';
import authenticated_de from 'Lang/resources/de/authenticated.json';
import authenticated_fr from 'Lang/resources/fr/authenticated.json';
import authenticated_es from 'Lang/resources/es/authenticated.json';
import admin_en from 'Lang/resources/en/admin.json';
import admin_de from 'Lang/resources/de/admin.json';
import admin_fr from 'Lang/resources/fr/admin.json';
import admin_es from 'Lang/resources/es/admin.json';

const instance = axios.create();
instance.defaults.headers.common = {};

export const LangContext = createContext();

const defaultTranslations = {
  de: {
    anonymous: anonymous_de,
    authenticated: authenticated_de,
    admin: admin_de
  },
  en: {
    anonymous: anonymous_en,
    authenticated: authenticated_en,
    admin: admin_en
  },
  es: {
    anonymous: anonymous_es,
    authenticated: authenticated_es,
    admin: admin_es
  },
  fr: {
    anonymous: anonymous_fr,
    authenticated: authenticated_fr,
    admin: admin_fr
  }
};

export const getTranslationNameSpaces = ({ isAuthenticated = false, isAdmin = false } = {}) => {
  if (isAdmin) {
    return [i18nextNamespaces.admin, i18nextNamespaces.authenticated];
  }
  if (isAuthenticated) {
    return [i18nextNamespaces.authenticated];
  }
  return [i18nextNamespaces.anonymous];
};

const fetchTranslation = async (language, namespace) => {
  try {
    const response = await instance.get(`${Urls.get().blob}/translations/${language}/${namespace}.json`);
    return {
      translation: response.data,
      language,
      namespace
    };
  } catch (error) {
     return { language, namespace };
  }
};

export const loadTranslations = async (languages = [], namespaces = []) => {
  const promises = [];
  languages.forEach((language) => {
    const languageCode = typeof language === "string" ? language : language.Code;
    namespaces.forEach((namespace) => {
      if (i18next.hasResourceBundle(languageCode, namespace)) {
        return;
      }
      promises.push(fetchTranslation(languageCode, namespace));
    })
  });
  return Promise.allSettled(promises).then((results) => {
    results.forEach(({ value }) => {
      i18next.addResourceBundle(
        value.language,
        value.namespace,
        value.translation || defaultTranslations[value.language][value.namespace]
      );
    });
  });
};

const isLanguageAvailable = (language, availableLanguages) => {
  return availableLanguages.some(({ Code }) => Code === language.toLowerCase());
};

const LangState = (props) => {
  const initialState = {
    lang: ''
  };

  const [state, dispatch] = useReducer(langReducer, initialState);
  const handleError = useHandleError();

  const getLang = (
    userLanguage,
    availableLanguages = []
  ) => {
    if (state.lang) {
      return state.lang;
    }

    if (
      userLanguage &&
      isLanguageAvailable(userLanguage, availableLanguages)
    ) {
      return setLang(userLanguage);
    }

    const localStorageLang = localStorage.getItem('i18nextLng');
    if (
      localStorageLang &&
      isLanguageAvailable(localStorageLang, availableLanguages)
    ) {
      return setLang(localStorageLang);
    }

    const userBrowserLanguage = (
      navigator.language || navigator.userLanguage
    ).slice(0, 2); // only use language prefix: e.g. "en" from "en-US"

    const translationAvailable = isLanguageAvailable(
      userBrowserLanguage,
      availableLanguages
    );

    if (translationAvailable) {
      return setLang(userBrowserLanguage);
    }

    const defaultLanguage = availableLanguages.find(({ IsDefault }) => IsDefault);
    return setLang(defaultLanguage.Code);
  };

  const setLang = (lang, user) => {
    if (!lang) return;
    if (i18next.language !== lang) {
      i18next.changeLanguage(lang.toLowerCase());
    }
    if (localStorage.getItem('i18nextLng') !== lang) {
      localStorage.setItem('i18nextLng', lang);
    }
    dispatch({ type: SET_LANG, payload: lang });
    // Update asynchronous in backend if logged in
    if (user) {
      try {
        api.User.updateDelta(user.profile.sub, {
          Language: lang.toLowerCase(),
        });
      } catch (error) {
        const message = i18nextKeys.accountSetLanguageError;
        handleError({ error, message });
      }
    }
    return lang.toLowerCase();
  };

  return (
    <LangContext.Provider
      value={{
        lang: state.lang,
        getLang,
        setLang,
      }}
    >
      {props.children}
    </LangContext.Provider>
  );
};

export default LangState;
