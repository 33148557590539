import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

const fallbackLng = 'en';
const defaultLocale = 'en-EN';

const websiteLangToLocale = {
  en: 'en-EN',
  es: 'es-AR',
  de: 'de-DE',
  fr: 'fr-FR'
};

const i18nextNamespaces = {
  anonymous: "anonymous",
  authenticated: "authenticated",
  admin: "admin"
}

i18next
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng,
    debug: false,
    ns: Object.values(i18nextNamespaces),
    defaultNS: i18nextNamespaces.anonymous,
    fallbackNS: i18nextNamespaces.authenticated,
    interpolation: {
      escapeValue: false
    },
  });

export { i18next as default, defaultLocale, websiteLangToLocale, i18nextNamespaces };
