import React from 'react';
import DefaultCardButtonGroup from 'Components/shared/cards/DefaultCardButtonGroup';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

const CollectionMethodCardButtonGroup = ({
  collectionMethodType,
  collectionMethod,
  openInfoModal = () => {},
  selectCollectionMethod = () => {},
  dataQa = "collectionMethod"
}) => {
  return (
    <DefaultCardButtonGroup
      buttons={[
        {
          text: i18nextTranslate(i18nextKeys.buttonMoreDetails),
          onClick: () => openInfoModal(
            collectionMethodType,
            selectCollectionMethod,
            collectionMethod
          )
        },
        {
          text: i18nextTranslate(i18nextKeys.buttonSelectAndContinue),
          onClick: () => selectCollectionMethod({ collectionMethodType, wallet: collectionMethod })
        }
      ]}
      dataQa={dataQa}
    />
  );
};

export default CollectionMethodCardButtonGroup;
