import React, { useContext, useEffect, useState } from 'react';
import api from 'Api';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { i18nextKeys } from 'Lang/i18nextKeys';
import useHandleError from 'Utils/handleError';
import useConfigSettings from 'Hooks/useConfigSettings';
import { LangContext } from 'States/lang/langState';
import ConfirmationModal from "Components/shared/ConfirmationModal";
import { Checkbox, DropdownField } from 'Components/shared/formElements';
import Text from 'Components/shared/Text';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { WarningIcon } from "Components/shared/symbols";
import SubsectionHeading from '../../shared/SubsectionHeading';

const LanguagesAndCurrenciesConfig = ({
  titleRef,
  dataQa = "languagesAndCurrencies"
}) => {
  const handleError = useHandleError();
  const [availableLanguages, setAvailableLanguages] = useState([]);
  const [activeLanguages, setActiveLanguages] = useState([]);
  const [updatingActiveLanguages, setUpdatingActiveLanguages] = useState(false);
  const [defaultLanguage, setDefaultLanguage] = useState({});
  const [currencies, setCurrencies] = useState([]);
  const [showDefaultLanguageModal, setShowDefaultLanguageModal] = useState(false);
  const { lang } = useContext(LangContext);
  const { data: settings } = useConfigSettings.query({
    onSuccess: (data) => translateActiveLanguages(data.Languages),
  });

  useEffect(() => {
    const getLanguagesAndCurrencies = async () => {
      try {
        const { value: availableLanguages } = await api.Config.Languages.get();
        const { value: currencies } = await api.Config.Currencies.get();
        setAvailableLanguages(availableLanguages);
        currencies.sort((a, b) => a.Code.localeCompare(b.Code));
        setCurrencies(currencies);
      } catch (error) {
        handleError({ error })
      }
    };
    getLanguagesAndCurrencies();
  }, []);

  useEffect(() => translateActiveLanguages(settings.Languages), [lang]);

  const translateActiveLanguages = (languages) => {
    const activeLanguages = languages.map(({ Code, IsDefault }) => {
      const option = {
        value: Code,
        label: i18nextTranslate(i18nextKeys.adminLanguage, { context: Code })
      };
      if (IsDefault) {
        setDefaultLanguage(option);
      }
      return option;
    });
    activeLanguages.sort((a, b) => a.label.localeCompare(b.label));
    setActiveLanguages(activeLanguages);
    setUpdatingActiveLanguages(false);
  };

  const settingsMutation = useConfigSettings.mutation({});

  const toggleLanguage = ({ language, enable }) => {
    if (language === defaultLanguage.value) {
      setShowDefaultLanguageModal(true);
    } else {
      updateActiveLanguages({ language, enable });
    }
  };

  const updateActiveLanguages = ({ language, enable, newDefaultLanguage = {} }) => {
    setUpdatingActiveLanguages(true);
    let updatedLanguages = [];
    if (enable) {
      updatedLanguages = [
        ...settings.Languages,
        { Code: language, IsDefault: false }
      ];
    } else {
      updatedLanguages = settings.Languages.reduce((updated, { Code, IsDefault }) => {
        if (Code === newDefaultLanguage.value) {
          updated.push({ Code, IsDefault: true });
        } else if (Code !== language) {
          updated.push({ Code, IsDefault });
        }
        return updated;
      }, []);
    }
    settingsMutation.mutate({
      data: {
        Languages: updatedLanguages
      }
    });
  };

  const updateDefaultLanguage = (value) => {
    if (defaultLanguage.value === value) {
      return;
    }
    const updatedLanguages = settings.Languages.map(({ Code }) => (
      { Code, IsDefault: Code === value }
    ));
    settingsMutation.mutate({
      data: {
        Languages: updatedLanguages
      }
    });
  };

  const updateDefaultCurrency = (value, Currency) => {
    if (settings.Currency.Code === value) {
      return;
    }
    settingsMutation.mutate({ data: { Currency } });
  };

  const isActiveLanguage = (language) => {
    return activeLanguages.some(({ value }) => value === language);
  };

  const isCheckboxDisabled = (language) => {
    return (isActiveLanguage(language) && activeLanguages.length === 1) || updatingActiveLanguages;
  };

  const getNewDefaultLanguage = () => {
    return activeLanguages.find(({ value }) => value !== defaultLanguage.value);
  };

  return (
    <>
      {settings && currencies.length && availableLanguages.length ? (
        <div data-qa={dataQa}>
          <Text
            textStyle="h2"
            className="mb-24 xxl:mb-32"
            dataQa={`${dataQa}-title`}
            reference={titleRef}
            uppercase
          >
            {i18nextTranslate(i18nextKeys.tenantSettingsLanguagesAndCurrenciesTitle)}
          </Text>
          <SubsectionHeading
            text={`${i18nextTranslate(i18nextKeys.tenantSettingsLanguagesAvailableTitle)} *`}
            tooltipText={i18nextTranslate(i18nextKeys.tenantSettingsLanguagesAvailableTooltip)}
            dataQa="language-available"
          />
          <div className="flex gap-40">
            {availableLanguages.map((language) => (
              <div
                key={language}
                className="flex items-center gap-8"
              >
                <Checkbox
                  checked={isActiveLanguage(language)}
                  disabled={isCheckboxDisabled(language)}
                  onChange={(e) => toggleLanguage({ language, enable: e.target.checked })}
                  dataQa={`language-available-${language}-checkbox`}
                />
                <Text
                  dataQa={`language-available-${language}-label`}
                >
                  {i18nextTranslate(i18nextKeys.adminLanguage, { context: language })}
                </Text>
              </div>
            ))}
          </div>
          <div className="flex justify-between mt-40 xxl:mt-48">
            <div style={{ width: '45%' }}>
              <SubsectionHeading
                text={`${i18nextTranslate(i18nextKeys.adminLanguage)} *`}
                tooltipText={i18nextTranslate(i18nextKeys.tenantSettingsLanguagesDefaultTooltip)}
                dataQa="language-default"
              />
              <DropdownField
                label={i18nextTranslate(i18nextKeys.tenantSettingsLanguagesDefaultSelect)}
                options={activeLanguages}
                input={{
                  value: defaultLanguage,
                  onChange: updateDefaultLanguage
                }}
                disabled={activeLanguages.length === 1}
                meta={{}}
                dataQa="language-default"
                required
              />
            </div>
            <div style={{ width: '45%' }}>
              <SubsectionHeading
                text={`${i18nextTranslate(i18nextKeys.commonCurrency)} *`}
                tooltipText={i18nextTranslate(i18nextKeys.tenantSettingsCurrencyTooltip)}
                dataQa="currency"
              />
              <DropdownField
                label={i18nextTranslate(i18nextKeys.tenantSettingsCurrencySelect)}
                options={currencies}
                valueKey="Code"
                labelKey="Code"
                input={{
                  value: settings.Currency,
                  onChange: updateDefaultCurrency
                }}
                meta={{}}
                placeholder={i18nextTranslate(i18nextKeys.tenantSettingsCurrencyPlaceholder)}
                dataQa="currency"
                isSearchable
                required
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center">
          <LoadingSpinner
            dataQa={`${dataQa}-loading`}
          />
        </div>
      )}
      {showDefaultLanguageModal && (
        <ConfirmationModal
          show={showDefaultLanguageModal}
          close={() => setShowDefaultLanguageModal(false)}
          onConfirm={() => {
            updateActiveLanguages({
              language: defaultLanguage.value,
              enable: false,
              newDefaultLanguage: getNewDefaultLanguage()
            });
            setShowDefaultLanguageModal(false);
          }}
          confirmButtonText={i18nextTranslate(i18nextKeys.buttonConfirm)}
          icon={<WarningIcon size="30" />}
          title={i18nextTranslateDynamically(
            i18nextKeys.tenantSettingsLanguagesDefaultModalTitle,
            { defaultLanguage: defaultLanguage.label }
          )}
          description={i18nextTranslateDynamically(
            i18nextKeys.tenantSettingsLanguagesDefaultModalDescription, {
              newDefaultLanguage: getNewDefaultLanguage().label
            }
          )}
        />
      )}
    </>
  );
};

export default LanguagesAndCurrenciesConfig;